/*@import './theme.less';*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
    position: relative;
    margin: 0;
    min-height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.site-layout-workspace {
    /* padding: 16px; */
    overflow-y: auto;
}

.back-button {
    border: 0px;
    background: transparent;
    /* padding: 0px; */
    line-height: inherit;
    display: inline-block;
}

.mod-title {
    margin-bottom: 0px;
}

.mod-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.mod-buttons button, .mod-buttons a {
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.footer-link {
    line-height: 24px;
    float: right;
}

.footer-link-text {
    margin-left: 8px;
}

.error {
    color: red;
    font-size: medium;
}

.mod-main-table {
    height: calc(100% - 50px);
}

.table-editable-row {
    cursor: pointer;
}

.table-update-row {
    font-weight: bold;
}

.filter-panel {
    margin: 8px 0px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    top: 28px;
}

.ant-collapse-extra {
    display: inline-block;
    position: absolute;
    right: 40px;
    left: auto;
    top: 17px;
}

.more-dropbutton {
    margin-left: 8px !important;
}

.more-dropbutton>.ant-btn:first-child:not(:last-child) {
    display: none;
}

.user-dropdown-login {
    position: absolute !important;
    margin: 0px 0px;
    right: 30px;
    color: white;
    border: none !important;
}

.main-menu-drawer-open {
    color: white;
    background-color: transparent;
    border: 0px;
    float: left;
    margin-left: 16px;
}


.ant-layout-sider-collapsed .logo {
    display: none;
}

.logo-img {
    display: none;
}

.ant-layout-sider-collapsed .logo-img {
    display: block;
}

.vhod {
    position: relative;
    margin-top: 50px;
    font-style: normal;
    font-size: 22px;
    line-height: 26px;
    color: #E31E24;
    text-align: center;
}

.header p{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background: rgba(46, 40, 37, 0.6);
    color: white;
    position: absolute;
    height: 60px;
    left: 1px;
    right: -1px;
    top: 0px;
}

.ant-input-affix-wrapper {
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 0px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
    background-color: rgb(238, 238, 238);
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    transition: all 0.3s;
    display: inline-flex;
}

#size {
    font-size: 14px;
    padding-left: 5px;
}

.header {
    padding-top: 25px;
}

.logotype {
    display: flex;
    justify-content: center;
}

/*.main {*/
/*    position: absolute;*/
/*    margin: 0 auto;*/
/*    bottom: 18px;*/
/*    left: 0;*/
/*    right: 0;*/
/*    height: 474px;*/
/*    width: calc(100vw - 36px);*/
/*    max-width: 488px;*/

/*}*/

/* Медиа-запросы для мобильных устройств*/
/*@media only screen and (max-height: 610px) {*/
/*    .main {*/
/*        top: 110px;*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 520px) {*/
/*    .main {*/
/*        max-width: 350px;*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 380px) {*/
/*    .main {*/
/*        max-width: 250px;*/
/*    }*/
/*}*/

.main form {
    min-height: 474px;
    background: #2E2825;
    mix-blend-mode: normal;
    box-shadow: 0 5.42222px 5.42222px rgba(0, 0, 0, 0.25);
}

.ant-select-tree-title {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lookup button.ant-input-search-button {
    border-radius: 0px !important;
}

.lookup-button-dict {
    border-left-color: transparent;
}

.data-tree {
    overflow-y: auto;
    overflow-x: hidden;
}

.ant-tree-treenode {
    width: 100%;
}

.ant-tree .ant-tree-node-content-wrapper {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
}

.splitter-layout {
    position: inherit;
}

.splitter-layout>.layout-splitter {
    width: 8px;
    background-color: #eee7;
    margin: 0 4px;
}

.ant-table .ant-table-selection-col {
    width: 52px;
}

.cellContentCentered {
    display: flex;
    justify-content: center;
}

.cellContentRight {
    display: flex;
    justify-content: flex-end;
}

.ant-form-item-label > label {
    white-space: normal;
    line-height: 1.2;
}

.numbering .ant-input-group-addon {
    /* padding: 0px; */
}

.numbering button {
    height: 30px;
}

.numbering button:first-child {
    border: none;
}

.numbering button:not(:first-child) {
    border-top: none;
    border-bottom: none;
    border-right: none;
}

.noborder {
    border: 0px;
    background: transparent;
}

.align-right {
    float: right;
}


.drawer-main-menu .ant-drawer-body {
    /* padding: 0px; */
}

.drawer-main-menu .ant-drawer-header {
    /* padding-right: 8px; */
}

.mobile-menu-button {
    color: white;
    background-color: transparent !important;
    border: 0px;
    margin-left: 8px;
    box-shadow: none;
}

.mobile-menu-button.mobile-menu-button-color {
    color: inherit;
}

@media screen and (max-width: 767px) {
    .more-menu-button{
        float: right;
        margin-right: 56px;
    }
    .mob-filter-popup .ant-popover-inner-content {
        padding: 12px 16px;
        color: white;
        background: #3A3330;
    }
    .mob-filter-popup .ant-popover-title {
        min-width: 177px;
        min-height: 32px;
        margin: 0;
        padding: 5px 16px 4px;
        color: white;
        font-weight: 500;
        background: #3A3330;
        border-bottom: 1px solid #f0f0f0;
    }
}

@media screen and (min-width: 768px) {
    .more-menu-button{
        top: 16px;
        float: right;
        margin-right: 56px;
    }
}

.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(4.24264069px) rotate(45deg);
  background: #3A3330;
}

.ant-row {
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
}
.ant-select-selection-overflow {
    position: relative;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: hidden;
}
.ant-col-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 100%;
  }


.ant-dropdown-button.more-menu-button button{
    color: white;
    background-color: transparent;
    border: 0px;
    margin-left: 8px;
}

.text_menu_button span {
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 40px;
    line-height: 0.8;
}

.ant-table-expand-icon-col {
    width: 24px;
}

.ant-table-cell .ant-table-wrapper .ant-table {
    background-color: transparent;
}

.ant-table-cell .ant-spin-container {
    background-color: #F4CB5544;
}

.pad64 .ant-collapse-header {
    /* padding-right: 64px !important; */
}

.main-breadcrumb {
    margin: 16px 0;
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textcol {
    color: white;
}
.textcol span {
    line-height: 2;
}

.ant-tabs-tab-disabled {
    display: none;
}

.ant-form-item {
    margin-bottom: 12px;
}

.mod-header .ant-form-item {
    margin-bottom: 0px;
}

.inputSummary {
    font-weight: bold;
    color: black !important;
    background-color: #e6ffe6 !important;
}

.newRecord {
    background-color: #caf782;
}

/* .ant-select-allow-clear .ant-select-clear {

} */

/* .ant-select-allow-clear.ant-select-show-arrow .ant-select-clear {
    right: 32px;
} */

.ant-select-dict.ant-select-allow-clear .ant-select-clear {
    right: 74px;
}

.row-menu-button {
    display: table-cell;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background-color: transparent;
    text-align: center;
    border-radius: 20px;
}

.row-menu-button:hover {
    background-color: #be1f25;
}


.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    /* padding: 5px 12px; */
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    /* padding: 4px 11px; */
    border: 1px solid rgb(217, 217, 217);
    border-radius: 2px;
    background-color: rgb(245, 245, 245) !important;
}

#red {
    background: #E31E24;
    border: none;
    padding: 0.8px;
    width: 100%;
    margin-bottom: 30px;
}
.gradient {
    margin: 0;
    background: linear-gradient(90deg, rgba(227, 30, 36, 0) 0%, #E31E24 49.48%, rgba(227, 30, 36, 0) 100%);
    border: none;
    height: 2px;
    margin-bottom: 4px;
    width: 100%;
    margin-top: 25px;
}


#ok-btn {
    width:100%;
}
.mod-buttons {
    display:flex;
}

.ant-input:placeholder-shown,.ant-cascader-picker, .ant-select-selector,.ant-input {
border-radius: 7px!important;
height:35px;
}

.ant-menu-item.ant-menu-item-only-child {
    /* padding-left: 50px!important;
    padding-right: 50px!important; */
}
.ant-menu-title-content {
    font-size:11px;
}

#p-admin{
    color:#fff;
}
.anticon.anticon-exclamation-circle {
    color: #e31e24 !important;;
}
.ant-modal-confirm-title{
    color: #fff !important;
    font-weight: 400!important;
}
.ant-modal-confirm-btns {
    display:flex;
}
.ant-btn > span {
    font-size:18px!important;
}
.ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    max-width:100%!important;
    width: 100% !important;
}
.ant-btn.ant-btn-icon-only.ant-input-search-button,.ant-input-group-addon {
    display:none;
}


.ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    box-shadow: none;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
  background: #2E2825;
  color: white;
}

.ant-collapse-content {
    color: white;
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
}
.ant-pagination-item-ellipsis,.ant-pagination-item-link-icon{
    color: #fff !important;
}
.ant-collapse-item.ant-collapse-item-active.pad64,.ant-collapse-item.pad64 {
    border-color: #3a3330 !important;
}
.ant-picker.ant-picker-range {
    border-radius:4px;
    height:35px;
}
.ant-space.ant-space-horizontal.ant-space-align-center {
    gap: 15px !important;;
}

.ant-btn.ant-btn-icon-only.close{
    background: #ffffff!important;
    border-color: #d9d9d9 !important;
    border-radius:0 5px 5px 0!important;
    height: 37px!important;
    box-shadow: none!important;
}
.anticon.anticon-close {
    color:#888888!important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.mob-button-panel {
    background: #2E2825;
}

.ant-drawer-header {
    position: relative;
    /* padding: 16px 24px; */
    color: rgba(0, 0, 0, 0.85);
    background: rgba(46, 40, 37, 0.6);
    border-bottom: 0px;
    border-radius: 2px 2px 0 0;
}

.ant-drawer-body {
    flex-grow: 1;
    /* padding: 24px; */
    overflow: auto;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    background: #25201d;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: white;
}

.ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    background: #25201d;
}

.ant-drawer-title {
    margin: 0;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.sys-user-login {
    color:red;
}

.ant-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
    color: white;
}

.__dialog__modal-settings .ant-modal-body {
    /* padding: 0px 24px 24px 24px; */
}

.app-version {
    font-style: italic;
    text-align: center;
    font-size: smaller;
}

.ant-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    color: black;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background: white;
    border-radius: 2px;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
    font-size: 11px;
    cursor: pointer;
    /* padding-left: 50px;
    padding-right: 50px; */

}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  /* padding-left: 8px; */
}
.ant-tooltip-arrow-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 5px;
    height: 5px;
    margin: auto;
    background: white;
    content: '';
    pointer-events: auto;
}


.ant-tooltip-inner:hover{
    color: #e31e24;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  /* padding: 0 10px; */
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  /* padding-left: 50px;
  padding-right: 50px; */
}

.ant-upload-list-text .ant-upload-text-icon .anticon {
    position: static;
    color: white;
}

.typedevice {
    white-space: nowrap;
    margin-right: 8px;
    overflow: hidden;
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
}

.typedevice:hover {
    color: black;
}

.table-field-as-link:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.ant-space-horizontal .ant-space-item:last-child {
    flex-grow: 1;
}

.btn-small {
    height: 30px;
    font-size:12px;
}
.btn-small > span {
    font-size:12px!important;
}

.file-list-info {
    margin-left: 24px;
    width: 80px;
    display: inline-block;
    text-align: right;
}

.number-incidents {
    margin-left: 5px;
    font-size: 10px;
}

.form-device {
    margin-bottom: -18px;
}



@media only screen and (max-width: 600px) {
    .btn-small > span {
        font-size: 10px!important;
    }
    .number-incidents {
        font-size: 8px;
    }
}

.search {
    width: 200px;
    margin-left: 8px;
}

table {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.filter-panel-close-btn {
    margin-right: 8px;
    color: #e6f7ff;
    position: absolute;
    top: 16px;
    right: 8px;
}

.ant-table-cell .ant-spin-container {
    background-color: transparent !important;
}

.__dialog__column__menu .ant-menu {
    color: #fff;
    background-color: #2e2825 !important;
}

.__dialog__column__menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.__dialog__column__menu .ant-menu-item-active {
    background-color: #2e2825 !important;
    color: #fff;
}

.__dialog__column__menu .ant-menu-item:hover {
    color:#fff;
}

.__dialog__column__menu .ant-menu-title-content {
    font-size: 14px;
}

.noborder.align-right {
  background: none;
}

.ant-select-selector {
    height: 35px!important;
}

.wrap-3 {
    position: absolute;
    top: 105px;
    bottom: 0;
    right: 0;
    left: 0;

}
.wrap-2 {
    display: table;
    height: 100%;
    position: relative;
    margin: auto;
    min-height: 474px;
    width: calc(100vw - 36px);
    max-width: 488px;
}
.wrap-1 {
    display: table-cell;
    vertical-align: middle;
}

.__dialog__typedevices-frm .ant-modal-body {
    height: 600px !important;    
}

.ant-modal-body {
    overflow: hidden;
}

.tabmetadata {
    display: flex;
    align-items: stretch;
}

.tabmetadata .command {
    display: inline-block;
    width: 36px;
}

.tabmetadata textarea {
    display: inline-block;
    width: calc(100% - 36px);
    resize: none;
}

/*TextArea scroll bar*/
textarea {
    cursor: auto;
}

textarea::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 12px;
}

textarea::-webkit-scrollbar {
  width: 10px;
  border-radius: 12px;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 12px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

textarea::-webkit-resizer {
    background-color: #eeeeee;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12'><line x1='1' y1='8' x2='8' y2='1' stroke='%23999' /><line x1='5' y1='8' x2='9' y2='4' stroke='%23999' /></svg>");
}

textarea::-webkit-scrollbar-corner {
    background-color:transparent;
}
