@import '~antd/dist/antd.less';

@font-face {
    font-family: 'Montserrat_400';
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
         url('../fonts/Montserrat-Regular.woff') format('woff'),
         url('../fonts/Montserrat-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat_500';
    src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
         url('../fonts/Montserrat-SemiBold.woff') format('woff'),
         url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat_600';
    src: url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
         url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
         url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
  }

*{
    font-family: 'Montserrat_400', sans-serif !important;
    box-sizing: border-box;
}

@primary-color:#E31E24;
@primary-color-light:#E31E24;
@success-color:#52c41a;
@link-color:#fff;
@switch-color:#E31E24;
@layout-header-background:#25201D;
@layout-sider-background:@layout-header-background;
@disabled-color: rgba(0, 0, 0, 0.5);
@label-color: #fff;

@menu-item-color:#25201D;
@text-color:  #fff; // major text color
@text-color-secondary:  #25201D; // secondary text color

@text-color-on-light-background:  #25201D; // цвет текста на светлом фоне

.main-menu {
    background-color: @layout-header-background !important;
    color:@link-color;
    border: 0;
}

.main-menu .ant-menu-item-selected a,
.main-menu.ant-menu-item-selected a {
    color:@primary-color-light !important;
}

.main-menu .ant-menu-item a,
.main-menu.ant-menu-item a {
    color:@link-color !important;
}

.main-menu .ant-menu-item a:hover,
.main-menu.ant-menu-item a:hover {
    color:@primary-color-light !important;
}

.main-menu .ant-menu-item-selected,
main-menu.ant-menu-item-selected {
    background-color: @layout-header-background !important;
}

.main-menu .ant-menu-sub.ant-menu-inline {
    background-color: @layout-header-background;
    color:@link-color;
}

.ant-menu-submenu-selected {
    color: white;
}
  
.ant-table-tbody tr.ant-table-row:hover td {
    background:@primary-color-light !important;
}

.ant-collapse-extra:hover {
    color:@primary-color;
}
  
.ant-modal-confirm-content {
    margin-left: 0px !important;
}

.ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
        background: @link-color!important;
}

.ant-layout-sider-trigger{
    background: none!important;
}

.ant-layout {
    background: #3A3330!important;
}
.ant-breadcrumb-link, .ant-breadcrumb-separator {
    color:@link-color!important;
}

.ant-breadcrumb-link:hover{
color: @switch-color !important;
}
.mob-button-panel .ant-btn.ant-btn-icon-only{
    color:@link-color;
    background: #2E2825;
    border-color: #2E2825;
    box-shadow: none;
    height: 37px!important;
}

.mob-button-panel .ant-btn.ant-btn-icon-only.user-dropdown-login{
    color:@link-color;
    background: #2E2825;
    border-color: #2E2825;
    box-shadow: none;
    height: 37px!important;
}
@media screen and (max-width: 767px) {
    .user-dropdown-login {
        position: absolute !important;
        margin: 0px 0px;
        right: 30px;
        color: white;
        border: none !important;
        background-color: #2E2825!important;
    }
}
.ant-btn.ant-btn-icon-only {
    color:@link-color;
    background: #3A3330;
    border-color: #3A3330;
    box-shadow: none;
    height: 37px!important;
}
.ant-btn.ant-btn-icon-only:hover {
    color: @primary-color;
}

.ant-btn.ant-btn-icon-only[disabled] {
    color: rgb(136, 136, 136);
    background: #3A3330;
    border-color: #3A3330;
    box-shadow: none;
}
.ant-table-cell {
    color: @text-color !important;
    background: #2E2825!important;
    border-color: #3A3330 !important;
}
.ant-table.ant-table-middle.ant-table-empty {
    background: none!important;
}
.mod-title,.ant-modal-title,.anticon.anticon-close,.anticon.anticon-left,
.anticon.anticon-right,.ant-pagination-item a, .ant-select-arrow {
    color: @text-color !important;
}
.ant-pagination-item:hover, .ant-pagination-item-active a{
    color: @switch-color !important;
}


.ant-modal-content{
    background-color:#2e2825 !important;
}
.ant-modal-header {
    background-color:#2e2825 !important;
    border-bottom: 1px solid #3A3330 !important;
}
.ant-modal-footer{
    border-top:none!important;
    padding-bottom: 25px!important;
    padding-left: 25px!important;
    padding-right: 25px!important;
}
.ant-pagination.mini .ant-pagination-item  {
    background-color:#3A3330 !important;
    border: none !important;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #3A3330 !important;
    border: none;
    color: @text-color;
}

.ant-drawer-header {
    position: relative;
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: rgba(46, 40, 37, 0.6);
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
}

.ant-btn {
    color: @text-color;
    border-radius: 8px;
    background: transparent;
    border-color: @primary-color;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24.4px;
    line-height: 30px;
    justify-content: center;
    height:46px;
}


.ant-btn-primary {
    color: @text-color;
    width: 100%;
    height: 46px;
    background: @primary-color;
    border-color: @primary-color;
    border-radius: 7px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24.4px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
}


.ant-input, 
.ant-notification,
.ant-picker-cell-in-view,
.ant-select-item,
.ant-picker-input > input,
.ant-select {
    color: @text-color-on-light-background;
}

.ant-picker {
    border-radius: 5px;
}

.ant-picker-input > input {
    padding: 2px 0px;
}    


.ant-table-row-expand-icon,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active,
.ant-table-row-expand-icon:focus {
    color: @switch-color !important;;
}

.ant-table-title {
   background-color: @layout-header-background;
}

.ant-table-title {
    background-color: @layout-header-background;
}

.ant-dropdown-menu-title-content {
    flex: auto;
    color: black;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: @primary-color;
}


.link {
    color: black;
    font-size: 11px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: black;
    background-color: #fac3c4;
}    

ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info 
.ant-upload-text-icon .anticon {
    color: @text-color !important;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: @primary-color !important;
}

.ant-upload-list-item-card-actions .ant-btn.ant-btn-icon-only {
    background: transparent;
    border-color: transparent;
}
.ant-upload-list-item-card-actions .anticon {
    color:@text-color !important;
}

.ant-upload-list {
    color: #ccc !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-tabs-dropdown-menu-item {
    color:@text-color-on-light-background;
}

.ant-select-arrow {
    color:@text-color-on-light-background !important;
}

.ant-pagination-options .ant-select-arrow {
    color:@text-color !important;
}

.ant-empty-normal {
    color:@text-color !important;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link,
.ant-pagination-item-active {
    background-color: transparent !important;
    border-color: transparent !important;
}

.ant-pagination-total-text {
    font-size: 14px;
}

.ant-form-item-explain-error {
    color: red !important;
}
    
.ant-empty-image {
    display: none;
}
.ant-row.ant-form-item.blocked{
    display: inline-block!important;
}

.ant-row.ant-form-item.blocked > .ant-col.ant-form-item-label {
    float: left;
    padding: 4px 10px 0px 0px;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 7px;
    background: rgb(238, 238, 238)
}

input.ant-input, .ant-form-item textarea.ant-input {
    background: rgb(238, 238, 238);
}

.ant-table-footer {
    color: @text-color !important;
    background: #2E2825!important;
    border-color: #3A3330 !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float:none !important;
}