.logo {
  height: 28px;
  margin: 13px 16px;
  line-height: 27px;
  color: white;
  font-size: x-large;
  margin-right: 30px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.logo-img {
  height: 45px;
  width: 45px;
  margin: 8px;
  overflow: hidden;
  margin-left: 18px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-row-disabled {
  color: lightgray;
}

.table-row-disabled .anticon {
  color: rgba(0, 0, 0, 0.85);
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.forget-pass {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 20px;
}

.status {
  max-height: 16px;
}

.statusDiv {
  width: 16px;
  height: 16px;
}

.ant-form-item-label > label::after {
  content: '' !important;
}